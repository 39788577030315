/* =========================================================
 * bootstrap-modal.js v2.3.2
 *
 *************************************** CUSTOM BUILD ****************************************
 * This lib is based off of bootstrap-dropdown 2.3.2, but has been modified slightly to allow
 * the option of deferEscapeHandling: true, which will prevent escape key from closing the modal.
 * This allows the modal to be used in conjunction with the Escape Stack, which will trigger
 * modal close if used in a ModalRegion
 * - bjones 1/28/2014
 * Add another option to trigger namespaced 'hidden.bs.modal' event, in order to differentiate
 * from, for instance, tooltip 'hidden' event
 * - bjones 2/4/2014
 *************************************** CUSTOM BUILD ****************************************
 * http://twitter.github.com/bootstrap/javascript.html#modals
 * =========================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================= */

!(function ($) {
  'use strict' // jshint ;_;

  /* MODAL CLASS DEFINITION
   * ====================== */

  var Modal = function (element, options) {
    this.options = options
    this.$element = $(element).delegate(
      '[data-dismiss="modal"]',
      'click.dismiss.modal',
      $.proxy(this.hide, this)
    )
    this.options.remote &&
      this.$element.find('.modal-body').load(this.options.remote)
  }

  Modal.prototype = {
    constructor: Modal,

    toggle: function () {
      return this[!this.isShown ? 'show' : 'hide']()
    },

    show: function () {
      var that = this,
        e = $.Event('show')

      this.$element.trigger(e)

      //There is a bug here where if a modal contains an element which has CSS transitions of its own,
      //this.$element.one($.support.transition.end, callback) will fire because such events bubble up to the
      //modal container! For instance, if you opened a modal with an input field with aura and then hit escape key,
      //the transitionend events associated with the input aura disappearing would fire potentially before the main
      //this.$element's fade out transition.
      //To solve, we simply prevent all transition end events on children
      //from bubbling up!
      this.$element
        .off($.support.transition.end, '*', stopEventPropagation)
        .on($.support.transition.end, '*', stopEventPropagation)

      if (this.isShown || e.isDefaultPrevented()) return $.Deferred().resolve()

      this.isShown = true

      this.escape()

      var deferred = new $.Deferred()
      this.backdrop(function () {
        var transition = $.support.transition && that.$element.hasClass('fade')

        if (!that.$element.parent().length) {
          that.$element.appendTo(document.body) //don't move modals dom position
        }

        that.$element.show()

        if (transition) {
          that.$element[0].offsetWidth // force reflow
        }

        that.$element.addClass('in').attr('aria-hidden', false)

        that.enforceFocus()

        var onShown = function () {
          that.$element.focus().trigger('shown.bs.modal')
          deferred.resolve()
        }
        if (transition) {
          that.$element.one($.support.transition.end, onShown)
        } else {
          onShown()
        }
      })
      return deferred.promise()
    },

    hide: function (e) {
      e && e.preventDefault()

      e = $.Event('hide.bs.modal')

      this.$element.trigger(e)

      if (!this.isShown || e.isDefaultPrevented()) return $.Deferred().resolve()

      this.isShown = false

      this.escape()

      $(document).off('focusin.modal')

      this.$element.removeClass('in').attr('aria-hidden', true)

      return $.support.transition && this.$element.hasClass('fade')
        ? this.hideWithTransition()
        : this.hideModal()
    },

    enforceFocus: function () {
      var that = this
      $(document).on('focusin.modal', function (e) {
        if (
          that.$element[0] !== e.target &&
          !that.$element.has(e.target).length
        ) {
          that.$element.focus()
        }
      })
    },

    escape: function () {
      var that = this
      if (this.isShown && this.options.keyboard) {
        this.$element.on('keyup.dismiss.modal', function (e) {
          e.which == 27 &&
            that.options.deferEscapeHandling !== true &&
            that.hide()
        })
      } else if (!this.isShown) {
        this.$element.off('keyup.dismiss.modal')
      }
    },

    hideWithTransition: function () {
      var that = this,
        timeout = setTimeout(function () {
          that.$element.off($.support.transition.end)
          that.hideModal().then(function () {
            deferred.resolve()
          })
        }, 300)
      var deferred = new $.Deferred()

      this.$element.one($.support.transition.end, function (e) {
        // Since the modal is _inside_ the backdrop now, we need to stop immediate propagation to prevent the
        // backdrop from reacting to this very same event and closing synchronously!
        e.stopImmediatePropagation()
        clearTimeout(timeout)
        that.hideModal().then(function () {
          deferred.resolve()
        })
      })
      return deferred.promise()
    },

    hideModal: function () {
      var that = this
      this.$element.hide()
      var deferred = new $.Deferred()
      this.backdrop(function () {
        that.removeBackdrop()
        that.$element.trigger('hidden.bs.modal')
        deferred.resolve()
      })
      return deferred.promise()
    },

    removeBackdrop: function () {
      this.$backdrop && this.$backdrop.hide()
      this.$backdrop = null
    },

    backdrop: function (callback) {
      var animate = this.$element.hasClass('fade') ? 'fade' : ''

      if (this.isShown && this.options.backdrop) {
        var doAnimate = $.support.transition && animate

        this.$backdrop = $('.modal-backdrop')
        this.$backdrop.show()

        // In Inbox (but not mailbox) we saw strange behavior when opening modals multiple times then closing them.
        // Turned out that the `click` event was being bound multiple times. To solve that, we make sure
        // existing handlers are removed before re-adding them below.
        this.$backdrop.off('click')
        this.$backdrop.click(
          this.options.backdrop == 'static'
            ? $.proxy(this.$element[0].focus, this.$element[0])
            : $.proxy(function (e) {
                // We have an issue (https://github.com/helpscout/hs-app/pull/839#issuecomment-178887666) where clicks
                // inside the modal bubble all the way up to the modal backdrop (which now wraps the `#modalRegion`.
                // Stop the modal from closing _unless_ the user clicked on the backdrop itself.
                if (e.currentTarget === e.target) {
                  this.hide(e)
                }
              }, this)
        )

        if (doAnimate) this.$backdrop[0].offsetWidth // force reflow

        this.$backdrop.addClass('in')

        if (!callback) return

        doAnimate
          ? this.$backdrop.one($.support.transition.end, callback)
          : callback()
      } else if (!this.isShown && this.$backdrop) {
        this.$backdrop.removeClass('in')

        $.support.transition && this.$element.hasClass('fade')
          ? this.$backdrop.one($.support.transition.end, callback)
          : callback()
      } else if (callback) {
        callback()
      }
    },
  }

  function stopEventPropagation(e) {
    e.stopPropagation()
  }

  /* MODAL PLUGIN DEFINITION
   * ======================= */

  var old = $.fn.modal

  $.fn.modal = function (option) {
    return this.each(function () {
      var $this = $(this),
        data = $this.data('modal'),
        options = $.extend(
          {},
          $.fn.modal.defaults,
          $this.data(),
          typeof option == 'object' && option
        )
      if (!data) $this.data('modal', (data = new Modal(this, options)))
      if (typeof option == 'string') data[option]()
      else if (options.show) data.show()
    })
  }

  $.fn.modal.defaults = {
    backdrop: true,
    keyboard: true,
    show: true,
  }

  $.fn.modal.Constructor = Modal

  /* MODAL NO CONFLICT
   * ================= */

  $.fn.modal.noConflict = function () {
    $.fn.modal = old
    return this
  }

  /* MODAL DATA-API
   * ============== */

  $(document).on('click.modal.data-api', '[data-toggle="modal"]', function (e) {
    var $this = $(this),
      href = $this.attr('href'),
      $target = $(
        $this.attr('data-target') ||
          (href && href.replace(/.*(?=#[^\s]+$)/, ''))
      ), //strip for ie7
      option = $target.data('modal')
        ? 'toggle'
        : $.extend(
            { remote: !/#/.test(href) && href },
            $target.data(),
            $this.data()
          )

    e.preventDefault()

    $target.modal(option).one('hide', function () {
      $this.focus()
    })
  })
})(window.jQuery)
