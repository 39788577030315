/* Onsi Fakhouri <onsijoe@gmail.com>
 * backbone.cocktail v0.5.10
 * https://github.com/onsi/cocktail/ */
!(function (a) {
  'function' == typeof require && 'undefined' != typeof module && module.exports
    ? (module.exports = a(require('underscore')))
    : 'function' == typeof define
    ? define(['underscore'], a)
    : (this.Cocktail = a(_))
})(function (a) {
  var b = {}
  ;(b.mixins = {}),
    (b.mixin = function (c) {
      var d = a.chain(arguments).toArray().rest().flatten().value(),
        e = c.prototype || c,
        f = {}
      return (
        a.each(d, function (c) {
          a.isString(c) && (c = b.mixins[c]),
            a.each(c, function (b, c) {
              if (a.isFunction(b)) {
                if (e[c] === b) return
                e[c] &&
                  ((f[c] = f.hasOwnProperty(c) ? f[c] : [e[c]]), f[c].push(b)),
                  (e[c] = b)
              } else
                a.isArray(b)
                  ? (e[c] = a.union(b, e[c] || []))
                  : a.isObject(b)
                  ? (e[c] = a.extend({}, b, e[c] || {}))
                  : c in e || (e[c] = b)
            })
        }),
        a.each(f, function (b, c) {
          e[c] = function () {
            var c,
              d = this,
              e = arguments
            return (
              a.each(b, function (b) {
                var f = a.isFunction(b) ? b.apply(d, e) : b
                c = 'undefined' == typeof f ? c : f
              }),
              c
            )
          }
        }),
        c
      )
    })
  var c
  return (
    (b.patch = function (d) {
      c = d.Model.extend
      var e = function (a, d) {
        var e = c.call(this, a, d),
          f = e.prototype.mixins
        return f && e.prototype.hasOwnProperty('mixins') && b.mixin(e, f), e
      }
      a.each([d.Model, d.Collection, d.Router, d.View], function (c) {
        ;(c.mixin = function () {
          b.mixin(this, a.toArray(arguments))
        }),
          (c.extend = e)
      })
    }),
    (b.unpatch = function (b) {
      a.each([b.Model, b.Collection, b.Router, b.View], function (a) {
        ;(a.mixin = void 0), (a.extend = c)
      })
    }),
    b
  )
})
