import axios from 'axios'

const AUTHORIZATION_TIMEOUT = 1

/*
Inspired by https://github.com/dirkbonhomme/pusher-js-auth

This Pusher authorizer provides the ability to batch Pusher channel
authentication requests. Instead of using the inspired library, we have written
similar code ourselves. This allows for us to pass our request data via the
request body, instead of via URL encoding. This solution solves for a
historical issue first addressed: https://github.com/helpscout/hs-app/pull/4634
where subscribing to large amounts of channels at once was leading to parsing
errors in PHP.
*/

function buildPusherBatchAuthorizer({
  socketId = '',
  authEndpoint = '',
  authHeaders,
}) {
  function buildChannelAuthRequestQueue() {
    let pendingAuthRequests = {}
    let requestTimeoutId = null

    return function queueChannelAuthRequest(channel, callback) {
      clearTimeout(requestTimeoutId)

      pendingAuthRequests = { ...pendingAuthRequests, [channel]: callback }

      requestTimeoutId = setTimeout(() => {
        executeRequests(pendingAuthRequests)
        pendingAuthRequests = {}
        requestTimeoutId = null
      }, AUTHORIZATION_TIMEOUT)
    }
  }

  async function executeRequests(pendingAuthRequests) {
    const channelNames = Object.keys(pendingAuthRequests)

    const response = await axios({
      method: 'post',
      data: { socket_id: socketId, channel_name: channelNames },
      headers: authHeaders,
      url: authEndpoint,
    })

    broadcastAuthRequestResponse(response, pendingAuthRequests)
  }

  function broadcastAuthRequestResponse(
    { data: responseData, status: responseStatus },
    authRequestsData
  ) {
    const authRequestsDataEntries = Object.entries(authRequestsData)

    for (const authRequestsDataEntry of authRequestsDataEntries) {
      const [channel, callback] = authRequestsDataEntry

      if (responseStatus !== 200) {
        callback(
          true,
          `Authentication request failed with status: ${responseStatus}.`
        )
        continue
      }

      const responseDataForChannel = responseData[channel]

      if (!responseDataForChannel) {
        callback(true, `${channel} channel not found.`)
        continue
      }

      const { status, data } = responseDataForChannel

      const authFailedForChannel = status && status !== 200

      if (authFailedForChannel) {
        callback(
          true,
          `Authentication request for ${channel}, returned status: ${status}.`
        )
        continue
      }

      callback(null, data)
    }
  }

  return {
    queueChannelAuthRequest: buildChannelAuthRequestQueue(),
  }
}

let authorizers = {}

function PusherBatchAuthorizer(channel, options) {
  const { authEndpoint, auth } = options

  return {
    authorize(socketId, callback) {
      const key = `${socketId}:${authEndpoint}`
      let authorizer = authorizers[key]

      if (!authorizer) {
        authorizer = authorizers[key] = buildPusherBatchAuthorizer({
          socketId,
          authEndpoint,
          authHeaders: auth.headers,
        })
      }

      authorizer.queueChannelAuthRequest(channel.name, callback)
    },
  }
}

export default PusherBatchAuthorizer
