import moment from 'moment-timezone'
import jQuery from 'jquery'
import 'jquery-ui'
import _ from 'underscore'
import Mousetrap from 'mousetrap'
import 'jquery.noty'
import './jquery.timeago'
import 'jquery.fancybox'
import Handlebars from 'handlebars'
import Backbone from 'hs-backbone'
import Marionette from 'backbone.marionette'
import Cocktail from './cocktail.min'
import _str from 'underscore.string'
import './jquery.jeditable'
import 'bootstrap'
import './jquery.deparam'
import './polyfills'
import HS from 'hs-core'
import PusherClient from 'pusher-client'
import '../core/browser-notifications'

Object.assign(window, {
  moment,
  jQuery,
  $: jQuery,
  _,
  _str,
  Mousetrap,
  Handlebars,
  Backbone,
  Marionette,
  Cocktail,
  HS,
  PusherClient,
})
