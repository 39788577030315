;(function () {
  if (typeof Object.values != 'function') {
    Object.values = function (target) {
      var vals = Object.keys(target).map(function (key) {
        return target[key]
      })

      return vals
    }
  }
})()
