import { init } from '@helpscout/attributionjs'

/**
 * Initialize Google Analytics
 */
;(function (i, s, o, g, r, a, m) {
  i['GoogleAnalyticsObject'] = r
  ;(i[r] =
    i[r] ||
    function () {
      ;(i[r].q = i[r].q || []).push(arguments)
    }),
    (i[r].l = 1 * new Date())
  ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
  a.async = 1
  a.src = g
  m.parentNode.insertBefore(a, m)
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga')

ga('create', 'UA-5226593-6', 'auto', { allowLinker: true })
ga('require', 'linkid', 'linkid.js')
ga('require', 'linker')
ga('linker:autoLink', ['helpscout.com'])
ga('send', 'pageview')

/**
 * Initialize attribution tracker
 */
init()
